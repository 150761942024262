import View from "./layouts/View";
import GraphicBar from "../components/graphics/GraphicBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function Dashboard() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type:'DASHBOARD_DATA' });
    },[]);

    return (
        <View>
            <section className='w-full flex items-center justify-between'>
                <div className='h-full w-1/2 p-4 flex flex-col items-center justify-center'>
                    <div className='w-4/5 p-4 mb-4 bg-gray-100 rounded font-bold'>
                        Admins: <span className='text-xl'>0</span>
                    </div>
                    <div className='w-4/5 p-4 mb-4 bg-gray-100 rounded font-bold'>
                        MercadoPago: <span className='text-xl'>0</span>
                    </div>
                    <div className='w-4/5 p-4 mb-4 bg-gray-100 rounded font-bold'>
                        Paypal: <span className='text-xl'>0</span>
                    </div>
                    <div className='w-4/5 p-4 mb-4 bg-gray-100 rounded font-bold'>
                        Gratuitos: <span className='text-xl'>0</span>
                    </div>
                    <div className='w-4/5 p-4 mb-4 bg-gray-100 rounded font-bold'>
                        Posnet: <span className='text-xl'>0</span>
                    </div>
                </div>
                <div className='h-full w-1/2 p-4 flex flex-col items-center justify-center'>
                    <div className='w-4/5 p-4 mb-4 bg-gray-100 rounded font-bold'>
                        Clases: <span className='text-xl'>0</span>
                    </div>
                    <div className='w-4/5 p-4 mb-4 bg-gray-100 rounded font-bold'>
                        Packs: <span className='text-xl'>0</span>
                    </div>
                </div>
            </section>
        </View>
    )
}
