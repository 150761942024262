import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function List(props) {
	const { bool, content, model, onEdit, dataToShow, dataToFilter } = props;
	// dataToShow: cosas que se muestran de cada item, dataToFilter: valores por los que se filtra la busqueda
	const [filteredList, setFilteredList] = useState([]);
	const [filterSearch, setFilterSearch] = useState('');
	const [editList, setEditList] = useState([]);
	const [showTitles, setShowTitles] = useState([]);

	const dispatch = useDispatch();
	const listSelector = useSelector(data => data[model]);

	function selectItem(ev, item) {
		if (ev.target.checked) {
			setEditList([...editList, item]);
		} else {
			const newList = editList.filter(i => i !== item);
			setEditList(newList);
		}
	}

	function HandleSelect() {
		if (editList.length > 0) {
			setEditList([]);
		} else {
			setEditList(filteredList);
		}
	}

	function handleExcel() {
		console.log('imprimir');
	}

	function handleDeleted() {
		const bulk = [];
		editList.forEach(i => {
			bulk.push(i.doc);
		})
		dispatch({type:'USER_DEL_REQUESTED', model, bulk});
	}

	function getMarkedItem(item) {
		const isInList = editList.includes(item);
		if (isInList) {
			return true;
		}

		return false;
	}

	function getSelectLabel() {
		if (editList.length > 0) {
			return 'Desmarcar todos';
		}

		return 'Marcar todos';
	}

	function getEditLabel() {
		if (editList.length > 0) {
			if (editList.length === 1) {
				return 'Editar item';
			}

			return 'Editar valor';
		}

		return 'Agregar nuevo';
	}

	useEffect(() => {
		dispatch({type:'USER_LIST_REQUESTED', listName: model});
	}, []);

	useEffect(() => {
		if (listSelector) {
			setFilteredList(listSelector);
			setEditList([]);
		}
	},[listSelector]);

	useEffect(() => {
		const data = [];

		dataToShow.forEach(element => {
			const item = content.find(c => c.Name === element);

			if (item) {
				data.push(item.Description);
			}
		});

		setShowTitles(data);
	}, []);

	useEffect(() => {
		if (listSelector && dataToFilter) {
			const newList = [];

			listSelector.forEach(item => {
				dataToFilter.forEach(filter => {
					if (item.doc[filter]){
						const includeSearch = item.doc[filter].includes(filterSearch);
						const isInList = newList.includes(item);
						if (includeSearch && !isInList) {
							newList.push(item);
						}
					}
				})
			})

			setFilteredList(newList);
			
		}
	}, [filterSearch, listSelector]);

	return (
		<section>
			<div className='w-full flex items-center justify-start bg-rtfitGreen flex items-center justify-start h-12'>
				<input placeholder='Buscar' className='mr-1 p-2 bg-white' type='text' value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} />
				{
					bool ?
					<div className='m-1 p-2 bg-white flex items-center justify-center'>
						<span className='mr-1 font-bold select-none'>{bool.label}</span>
						<input type="checkbox" name="" id="" className='select-none' />
					</div>
					:
					null
				}
				<button className='m-1 p-2 bg-white hover:bg-gray-200' onClick={() => onEdit(editList)}>{getEditLabel()}</button>
				<button className='m-1 p-2 bg-white hover:bg-gray-200' onClick={HandleSelect}>{getSelectLabel()}</button>
				<button className='m-1 p-2 bg-white hover:bg-gray-200' onClick={handleExcel}>Excel</button>
				<button className='m-1 p-2 bg-red-400 text-white hover:bg-red-500' disabled={editList.length < 1} onClick={handleDeleted}>Eliminar</button>
			</div>

			<div className="w-full flex flex-col items-center justify-start overflow-y-scroll" style={{height: 'calc(100vh - 3rem)'}}>
				<div className='w-full flex items-center justify-start'>
					<div className='w-full h-10 flex items-center justify-center bg-gray-200'>
						Seleccionar
					</div>
					{
						showTitles.map((cat, key) => {
							return (
								<div key={key} className='w-full h-10 flex items-center justify-center bg-gray-200'>
									{cat}
								</div>
							)
						})
					}
				</div>
				
				{
					filteredList.map((item, key) => {
						return (
							<div key={key} className='w-full flex items-center justify-start border-b border-gray-200'>
								<div className='w-full flex items-center justify-center'>
									<input className='select-none' checked={getMarkedItem(item)} type="checkbox" onChange={(ev) => selectItem(ev, item)} />
								</div>
								{
									dataToShow.map((cat, i) => {
										return (
											<div key={i} className='w-full flex items-center justify-center select-all'>
												{item.doc[cat] || '---'}
											</div>
										)
									})
								}
							</div>
						)
					})
				}
			</div>
		</section>
	)
}