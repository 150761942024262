import classNames from "classnames";
import { useEffect, useState } from "react";

export default function RtfitButton(props) {
    const { children, size, text, onClick, icon, color, darkcolor } = props;

    const [fontSize, setFontSize] = useState('');
    const [paddings, setPaddings] = useState('px-4 py-1 hover:px-5 hover:py-2');

    useEffect(() => {
        if (!!size) {
            if (size === 'medium'){
                setPaddings('px-4 py-2');
            }
            else if (size === 'big'){
                setFontSize('text-xl');
                setPaddings('px-8 py-4');
            }
            else if (size === 'small'){
                setFontSize('text-2xs');
                setPaddings('px-2 py-1');
            }
        }
    }, [size]);

    return (
        <div onClick={onClick} className={classNames('transition duration-150 ease-out rounded-full shadow-lg flex items-center justify-center cursor-pointer hover:bg-black hover:text-white hover:shadow-xl', `${!!color ? `bg-${color}` : 'bg-rtfitAcent'}`, `, ${fontSize} ${paddings} origin-center transform hover:scale-110`,`${darkcolor ? 'text-white':'text-rtfitBlack'}`)}>
            {
                !!text ?
                <span className='font-bold'>{text}</span>
                :
                null
            }
            {children}
            {!!icon ?
                <i className={`${icon} text-3xl ml-2`}/>
                :
                null
            }
        </div>
    );
}