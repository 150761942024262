import { createContext, useContext, useEffect, useState } from 'react';

const initialValue = {
    isMobile: false
};

export const GlobalContext = createContext(initialValue);

export function GlobalContextProvider({children}) {
    const [isMobile, setIsMobile] = useState(false);
    const [OpenModal, setOpenModal] = useState(false);

    function openModalAction(Component) {
        setOpenModal(Component);
    }

    function closeModalAction() {
        setOpenModal(null);
    }

    // Detecta el ancho de la pantalla y decide si es un celular o no.
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;

            if (width < 600) {
                setIsMobile(true);
            } else {  
                setIsMobile(false);
            }  
        };
        
        window.addEventListener('resize', handleResize);

         return () => window.removeEventListener('resize',handleResize);
    });

    return (
        <GlobalContext.Provider value={
            { closeModalAction, isMobile, OpenModal, openModalAction }
        }>
            {children}
        </GlobalContext.Provider>
    )
}

export function useGlobalContext() {
    const consumer = useContext(GlobalContext);

    return consumer;
}