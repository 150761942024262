import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useGlobalContext } from "../../contexts/GlobalContext";

export default function ValueForm(props) {
    const {model , itemList} = props;
    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState([]);
    const [selected, setSelected] = useState({ description: 'Elegir un campo', type: "text" });

    const { closeModalAction } = useGlobalContext();
    
    function handleSubmit() {
        let bulk = [];

        itemList.forEach(i => {
            bulk.push(i.doc);
        })

        dispatch({type:'BULK_EDIT_REQUESTED', bulk, key: selected.Name, value});
        closeModalAction();
    }

    function handleClose() {
        closeModalAction();
    }

    useEffect(() => {
        if (model) {
            const options = [{Type:'text', Description:'Elegir campo'}];

            model.Content.forEach(val => {
                options.push({ Name:val.Name, Description: val.Description, Type: val.Type });
            });

            setInputs(options);
        }
    }, []);

    useEffect(() => {
        if (selected.Type === 'bool') {
            setValue(false);
        } else {
            setValue('');
        }
    }, [selected])

    return (
        <div className='flex flex-col items-center justify-start  w-3/4'>
            <div className='flex items-center justify-between'>
                <select onChange={(ev) => setSelected(JSON.parse(ev.target.value))} className='border border-rtfitGreen p-2 mb-2 w-3/4 select-none'>
                    {
                        inputs.map((input, key) => {
                            return (
                                <option value={JSON.stringify(input)} key={key}>{input.Description}</option>
                            )   
                        })
                    }
                </select>
                {selected.Type === 'bool'?
                    <input type='checkbox' onChange={ev => setValue(ev.target.checked)}/>
                    :
                    <input value={value} onChange={ev => setValue(ev.target.value)} type={selected.Type} className='border border-rtfitGreen p-2 mb-2 w-3/4'/>
                }
            </div>
            <div className='flex items-center justify-between'>
                <button onClick={handleSubmit} className='p-2 m-2 bg-rtfitGreen text-white'>Guardar</button>
                <button onClick={handleClose} className='p-2 m-2 bg-red-400 text-white'>Cancelar</button>
            </div>
        </div>
    );
}