import View from "./layouts/View";
import List from "./layouts/List";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../contexts/GlobalContext";
import UserForm from '../components/forms/userForm';
import ValueForm from "../components/forms/ValueForm";
import { useState } from "react";
import { useEffect } from "react";

export default function Users(props) {
	const { model } = props;
	const configSelector = useSelector(selector => selector.config);
	const { openModalAction } = useGlobalContext();

	const [modelData, setModelData] = useState(null);

	function editList(itemList) {
		if (itemList.length < 2) {
			openModalAction(<UserForm model={modelData} itemList={itemList} />);
		} else {
			openModalAction(<ValueForm model={modelData} itemList={itemList} />);
		}
	}

	function deleteList(itemList) {
		console.log(itemList);
	}

	useEffect(() => {
		const md = configSelector.models[model];
		setModelData(md)
	}, []);

	return (
		<View>
			{
				modelData ?
				<List
					content={modelData.Content}
					dataToShow={modelData.dataToShow}
					dataToFilter={modelData.dataToFilter}
					bool={{label:'Inactivos', data:'Baja'}}
					model={model}
					onEdit={editList}
					onDelete={deleteList}
				/>
				:
				null
			}
		</View>
	)
}