import {useState} from 'react';
import RtfitInput from "../../components/webRtfit/forms/rtfitInput";
import RtfitButton from '../../components/webRtfit/rtfitButton';
import NavigationBar from "../../components/webRtfit/ui/navigationBar";

export default function Register(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');

    function emailValidation(newValue) {
        let state = { valid: true, message:''};

        if (!newValue.includes('@')) {
            state.valid = false;
            state.message = 'El campo debe ser un email valido.';
        }
        
        if (newValue.length === 0) {
            state.valid = false;
            state.message = 'El campo no debe estar vacio.';
        }

        return state;
    }

    function passwordValidation(newValue) {
        let state = { valid: true, message:''};
        
        if (newValue.length < 8) {
            state.valid = false;
            state.message = 'El campo no debe tener mas de 8 caracteres.';
        }

        if (newValue.length === 0) {
            state.valid = false;
            state.message = 'El campo no debe estar vacio.';
        }

        return state;
    }

    function rePasswordValidation(newValue) {
        let state = { valid: true, message:''};

        if (password !== newValue) {
            state.valid = false;
            state.message = 'Debe coincidir con la contraseña.';
        }

        return state;
    }

    function nameValidation(newValue) {
        let state = { valid: true, message:''};

        if (newValue.length === 0) {
            state.valid = false;
            state.message = 'El campo no debe estar vacio.';
        }

        return state;
    }

    function surnameValidation(newValue) {
        let state = { valid: true, message:''};

        if (newValue.length === 0) {
            state.valid = false;
            state.message = 'El campo no debe estar vacio.';
        }

        return state;
    }

    function succeededForm() {
        console.log('se valido todo el formulario');
    }

    function confirmForm() {
        let validAlInputs = true;
        const values = [email, password, rePassword, name, surname];
        const validations = [emailValidation, passwordValidation, rePasswordValidation, nameValidation, surnameValidation];

        values.forEach((input, index) => {
            if (!validations[index](input).valid) {
                validAlInputs = false;
            }
        })

        if (validAlInputs) {
            succeededForm();
        } else {
            console.log('faltan campos por validad');
        }
    }

    return (
        <div className='h-full w-screen flex flex-col items-center justify-start'>
            <div className='w-screen h-48 bg-rtfitAcent text-white text-3xl flex items-center justify-center'>
                REGISTRACION EN RTFIT
            </div>
            <div className='w-2/5 flex flex-col items-center justify-start'>
                <RtfitInput
                    title='Email'
                    value={email}
                    onChange={setEmail}
                    validation={emailValidation}
                />
                <div className='h-4 w-full'></div>
                <RtfitInput
                    title='Contraseña'
                    value={password}
                    type='password'
                    onChange={setPassword}
                    validation={passwordValidation}
                />
                <div className='h-4 w-full'></div>
                <RtfitInput
                    title='Confirmar Contraseña'
                    value={rePassword}
                    type='password'
                    onChange={setRePassword}
                    validation={rePasswordValidation}
                />
                <div className='h-4 w-full'></div>
                <RtfitInput
                    title='Nombre'
                    value={name}
                    onChange={setName}
                    validation={nameValidation}
                />
                <div className='h-4 w-full'></div>
                <RtfitInput
                    title='Apellido'
                    value={surname}
                    onChange={setSurname}
                    validation={surnameValidation}
                />
                <div className='h-4 w-full'></div>
                <RtfitButton
                    text='REGISTRARME'
                    onClick={confirmForm}
                />
            </div>
        </div>
    )
}