const url = 'http://200.58.99.151:5984/';// 'https://vps-1858514-x.dattaweb.com/' o 'http://200.58.99.151:5984/'
const db = 'rtfit/';
const urlParams = '?include_docs=true';

export async function makeRequest(method = 'GET', urlInfo, params, onSucceed, onFailed, auth) {
    let urlFetch = `${url}${db}${urlInfo}`;
    const objectData = {
        method: method,
        mode: 'cors',
        credentials: 'include',
        headers: {
        'Authorization': 'Basic '+btoa(`${auth.user}:${auth.pass}`),
        'Content-Type': 'application/json'
        }
    }

    if (method === 'POST' || method === 'PUT') {
        objectData.body = JSON.stringify(params);
    }
        // objectData.query = params;
        // urlFetch = urlFetch.concat('', urlParams);

    fetch(urlFetch, objectData)
    .then(res => {
        return res.json();
    })
    .then(res => {
        onSucceed(res);
    })
    .catch(err => {
        onFailed(err);
    });
}

export async function servicePut(data, body) {
        return fetch(`${url}${db}${data}`, {
            method:'PUT',
            mode: 'cors',
            credentials: 'include',
            headers: {
            'Authorization': 'Basic '+btoa('cecilia:admindemo'),
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((res) => {
            return res.json();
        })
        .then(result => {
            return result;
        }).catch(err => {
            return {error: err}
        })
}

export async function serviceGet(data) {
    return fetch(`${url}${db}${data}${urlParams}`, {
        method:'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
        'Authorization': 'Basic '+btoa('cecilia:admindemo'),
        'Content-Type': 'application/json'
        }
    }).then((res) => {
        return res.json();
    })
    .then(result => {
        return result;
    }).catch(err => {
        return {error: err}
    })
}

export async function serviceGetPartition(data) {
    
    return fetch(`${url}${db}_partition/${data}/_all_docs${urlParams}`, {
        method:'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
        'Authorization': 'Basic '+btoa('cecilia:admindemo'),
        'Content-Type': 'application/json'
        }
    }).then((res) => {
        return res.json();
    })
    .then(result => {
        return result;
    }).catch(err => {
        return {error: err}
    })
}

export async function serviceDeleteBulk(bulk) {
    let bulks = {
        docs: []
    };

    console.log(bulk);
    bulk.forEach(d => {
        bulks.docs.push({ ...d, _deleted: true });
    });

    console.log(bulks);

    const body = JSON.stringify(bulks);

    console.log(body);

    return fetch(`${url}${db}_bulk_docs/`, {
        method:'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
        'Authorization': 'Basic '+btoa('cecilia:admindemo'),
        'Content-Type': 'application/json'
        },
        body: body
    }).then((res) => {
        return res.json();
    })
    .then(result => {
        return result;
    }).catch(err => {
        return {error: err}
    })
}

export async function serviceEditBulk(bulk, key, value) {
    const bulks = {
        docs: []
    };

    bulk.forEach(d => {
        bulks.docs.push({ ...d, [key]: value });
    });

    return fetch(`${url}${db}_bulk_docs/`, {
        method:'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
        'Authorization': 'Basic '+btoa('cecilia:admindemo'),
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(bulks)
    }).then((res) => {
        return res.json();
    })
    .then(result => {
        return result;
    }).catch(err => {
        return {error: err}
    })
}

