import { all, call, delay, put, putResolve, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    USER_ADD, USER_EDIT, USER_DEL,
    FREE_ADD, FREE_DEL, FREE_EDIT,
    ADMIN_ADD, ADMIN_DEL, ADMIN_EDIT,
    CLASS_ADD, CLASS_DEL, CLASS_EDIT,
    PACK_ADD, PACK_DEL, PACK_EDIT,
    LOAD_CONFIG, LOAD_LIST
} from '../reducers/actions';
import { serviceGet, serviceGetPartition, servicePut, serviceDeleteBulk, serviceEditBulk } from '../services';

function* configSaga() {
    // CARGAR CONFIGURACION
    yield takeLatest(`${LOAD_CONFIG}_REQUESTED`, function* () {
        const config = yield serviceGet('config:admin');

        yield putResolve({type: LOAD_CONFIG, config});
        
    });

    yield takeEvery(LOAD_CONFIG, function* (){
        yield put({ type: `${LOAD_CONFIG}_SUCCEEDED` });
    });
}

function* userSaga() {
    // AGREGAR USUARIO
    yield takeLatest("USER_ADD_REQUESTED", function* ({user}) {
        yield put({type: 'USER_ADD_START', user});
    });
    
    yield takeLatest("USER_ADD_START", function* ({user}) {
        const add = yield servicePut(user._id,user);

        if (add.ok) {
            yield putResolve({type: 'USER_ADD_SUCCEEDED', user});
        } else {
            yield putResolve({type:'USER_ADD_FAIL', user});
        }
    });

    yield takeLatest("USER_ADD_FAIL", function ({user}) {
        console.log('fallo el agregar usuario', user);
    });

    yield takeLatest("USER_ADD_SUCCEEDED", function* ({user}) {
        console.log('exito al agregar usuario', user);
        delay(200);
        const listName = user._id.split(':')[0];

        yield put({type:'USER_LIST_REQUESTED', listName});
    });

    // EDITAR USUARIO
    yield takeLatest("USER_EDIT_REQUESTED", function* ({ user }) {
        yield put({type: 'USER_EDIT_START', user});
    });
    
    yield takeLatest("USER_EDIT_START", function* ({ user }) {
        const edit = yield servicePut(user._id,user);

        if (edit.ok) {
            yield putResolve({type: 'USER_EDIT_SUCCEEDED', user});
        } else {
            yield putResolve({type:'USER_EDIT_FAIL', user});
        }
    });

    yield takeLatest("USER_EDIT_FAIL", function ({ user }) {
        console.log('fallo el editar usuario', user);
    });

    yield takeLatest("USER_EDIT_SUCCEEDED", function* ({ user }) {
        console.log('exito al editar usuario', user);
        delay(200);
        const listName = user._id.split(':')[0];

        yield put({type:'USER_LIST_REQUESTED', listName});
    });

    // EDITAR USUARIOS

    yield takeLatest("BULK_EDIT_REQUESTED", function* ({ bulk, key, value }) {
        yield put({type: 'BULK_EDIT_START', bulk, key, value});
    });
    
    yield takeLatest("BULK_EDIT_START", function* ({ bulk, key, value }) {
        const edit = yield serviceEditBulk(bulk, key, value);

        if (edit.length > 0) {
            yield putResolve({type: 'BULK_EDIT_SUCCEEDED', bulk});
        } else {
            yield putResolve({type:'BULK_EDIT_FAIL', bulk});
        }
    });

    yield takeLatest("BULK_EDIT_FAIL", function ({ bulk }) {
        console.log('fallo el editar usuario', bulk);
    });

    yield takeLatest("BULK_EDIT_SUCCEEDED", function* ({ bulk }) {
        console.log('exito al editar los usuarios: ', bulk);
        delay(200);
        const user = bulk[0];
        const listName = user._id.split(':')[0];

        yield put({type:'USER_LIST_REQUESTED', listName});
    });

    // ELIMINAR USUARIO
    yield takeLatest("USER_DEL_REQUESTED", function* ({ bulk }) {
        yield put({type: 'USER_DEL_START', bulk});
    });

    yield takeLatest("USER_DEL_START", function* ({ bulk }) {
        const del = yield serviceDeleteBulk(bulk);

        if (del.length > 0) {
            yield putResolve({type: 'USER_DEL_SUCCEEDED', bulk});
        } else {
            yield putResolve({type:'USER_DEL_FAIL', bulk});
        }
        
    });

    yield takeLatest("USER_DEL_FAIL", function ({ bulk }) {
        console.log('fallo al eliminar los usuarios: ', bulk);
    });

    yield takeLatest("USER_DEL_SUCCEEDED", function* ({ bulk }) {
        console.log('exito al eliminar los usuarios: ', bulk);
        delay(200);
        const user = bulk[0];
        const listName = user._id.split(':')[0];

        yield put({type:'USER_LIST_REQUESTED', listName});
    });
}

function* userList() {
    yield takeLatest("USER_LIST_REQUESTED", function*({ listName }){
        yield put({type:"USER_LIST_START", listName});
    });

    yield takeLatest("USER_LIST_START", function*({ listName }){
        const list = yield serviceGetPartition(listName);

        yield putResolve({type: LOAD_LIST, listName, list: list.rows });

        if (list.total_rows > 0) {
            yield putResolve({type: 'USER_LIST_SUCCEEDED', listName});
        } else {
            yield putResolve({type:'USER_LIST_FAIL', listName});
        }
    });
}


function* mySaga() {
    yield all([
    call(configSaga),
    call(userSaga),
    call(userList)
    ]);
}

export default mySaga;