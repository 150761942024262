import Portal from "./components/Portal.jsx";
import { useGlobalContext } from "./contexts/GlobalContext.jsx";
import { routes } from "./constants/routes.js";
import {useRoutes} from 'hookrouter';

export default function AppPage(props) {
    const { OpenModal } = useGlobalContext();
    
    let list = {};

    routes.forEach(item => {
        list[item.url] = item.component;
    });

    const routeResult = useRoutes(list);

    return (
        <>
            {
                OpenModal ? <Portal/> : null
            }
            
            {routeResult}
        </>
    )
}