import { useState } from "react";

// Validation tiene que ser una funcion que dandole el nuevo valor devuelva un objeto del tipo {value, message} donde value es si esta validado o no y message el mensaje que responde(por ahora error).
export default function RtfitInput(props) {
    const { value, onChange, title,  validation, type } = props;
    //state
    const [error, setError] = useState(null);

    function handleChange(ev) {
        const newValue = ev.target.value;

        //valida el campo con la funcion de validation que pasemos
        if (validation(newValue).valid && error) {
            setError(null);
        } else {
            setError(validation(newValue).message);
        }

        onChange(newValue);
    }

    return (
        <div className='w-full flex flex-col items-center justify-center'>
            <label className={`text-sm ${error ? 'text-red-500' : 'text-rtfitAcent'} w-full`}>{title}</label>
            <input className='bg-rtfitGray text-gray-600 p-2 shadow-md w-full' type={type || 'text'} value={value} onChange={handleChange}/>
            {
                error ?
                <div className='text-xs text-red-500 w-full mt-2'>{error}</div>
                :
                null
            }
        </div>
    )
}