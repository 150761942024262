import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'hookrouter';


export default function Login() {
    const dispatch = useDispatch();
    const selector = useSelector(store => store);
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');

    function tryLogin() {
        dispatch({ type: "USER_LOGIN", username, pass });
        if ((username.toLowerCase() === 'ceci'.toLowerCase()) && (pass.toLowerCase() === 'rtfit'.toLowerCase())) {
            navigate('dashboard');
        }
        setUsername('');
        setPass('');
    }

    useEffect(() => {
        dispatch({type:"LOAD_CONFIG_REQUESTED"});
    }, []);

    return (
        <div className='w-screen h-screen flex items-center justify-center'>
            <div className='bg-rtfitGreen flex flex-col items-center justify-center shadow p-2' style={{width:'400px', height:'250px'}}>
                <span className='font-bold text-xl'>RTFIT</span>
                <input className='border border-rtfitGreen p-2 mb-2 w-full' type='text' value={username} onChange={ev => setUsername(ev.target.value)}/>
                <input className='border border-rtfitGreen p-2 mb-2 w-full' type='password' value={pass} onChange={ev => setPass(ev.target.value)}/>
                <div className='flex items-center justify-between'>
                    <button className='p-2 m-2 text-rtfitGreen bg-white' onClick={tryLogin}>
                        Ingresar
                    </button>
                </div>
            </div>
        </div>
    )
}