import { navigate } from 'hookrouter';
import { RTFIT_URLS } from '@constants/urls.js';
import Landing from "@root/components/webRtfit/layout/landing.jsx";
import RtfitButton from "@root/components/webRtfit/rtfitButton.jsx";
import NavigationBar from "@root/components/webRtfit/ui/navigationBar.jsx";
import Packs from '@root/components/webRtfit/layout/packs.jsx';
import { useGlobalContext } from '../../contexts/GlobalContext';

export default function FrontPage(props) {
    const { isMobile } = useGlobalContext();

    return (
        <div className='h-full w-screen flex flex-col items-center justify-start'>
            <NavigationBar />
            <Landing background={isMobile ? '/IMAGES/fondomobile1.png' : '/IMAGES/fondo1.png'}>
                {
                    isMobile ? 
                     <div className='h-full w-full'></div>
                    :
                        <>
                            <div className='w-full'>
                                <img className='w-8/12' src="/IMAGES/title1.png" alt="" />
                            </div>
                            <div className='mt-6 text-2xl text-rtfitBlack w-3/5 text-opacity-90'>
                                Entrenamiento guiado en tiempo real que te hará incorporar el hábito de moverte y sentirte mejor.
                            </div>
                        </>
                }
                <div className='md:mt-8 mb-20 md:mb-0 w-full flex items-center md:justify-start justify-center'>
                    <RtfitButton size='big' color='rtfitChallengeLau' text='QUIERO EL CHALLENGE' onClick={() => console.log('QUIERO EL CHALLENGE')} />
                </div>
            </Landing>
            <Landing background={isMobile ? '/IMAGES/fondomobile2.png' : '/IMAGES/fondo2.png'}>
                <div className='px-7 mt-56 md:mt-0 flex md:px-0 w-full'>
                    <img className='md:w-8/12 w-full' src="/IMAGES/title2.png" alt="" />
                </div>
                <div className='mt-6 px-7 md:px-0'>
                    <RtfitButton size='medium' text='SUSCRIBIRME' onClick={() => navigate(RTFIT_URLS.REGISTER)} />
                </div>
            </Landing>
            <Landing background={isMobile ? '/IMAGES/fondomobile3.png' :'/IMAGES/fondo3.png'}>
                <div className='w-full h-full md:h-0'></div>
                <div className='w-full px-7 md:px-0'>
                    <img className='md:w-8/12 w-10/12' src="/IMAGES/title3.png" alt="" />
                </div>
                <div className='md:mt-4 mt-2 w-full flex items-center justify-start md:mb-8 mb-4 px-7 md:px-0'>
                    <RtfitButton size={isMobile ? 'small' : 'medium'} color='white' onClick={() => console.log('NIVEL INICIAL')}>
                        <div>NIVEL <span className='font-bold'>INICIAL</span></div>
                    </RtfitButton>
                    <div className='w-4'></div>
                    <RtfitButton size={isMobile ? 'small' : 'medium'} color='white' onClick={() => console.log('NIVEL INTERMEDIO')}>
                        <div>NIVEL <span className='font-bold'>INTERMEDIO</span></div>
                    </RtfitButton>
                    <div className='w-4'></div>
                    <RtfitButton size={isMobile ? 'small' : 'medium'} color='white' onClick={() => console.log('NIVEL AVANZADO')}>
                        <div>NIVEL <span className='font-bold'>AVANZADO</span></div>
                    </RtfitButton>
                </div>
                <div className='md:mb-28 w-full px-7 md:px-0'>
                    <img className='md:w-6/12 w-9/12 mb-10 md:mb-0' src="/IMAGES/title4.png" alt="" />
                </div>
            </Landing>
            <Landing background={isMobile ? '/IMAGES/fondomobile4.png' :'/IMAGES/fondo4.png'}>
            <div className='w-full h-56 md:h-0'></div>
                <div className='w-full px-7 md:px-0'>
                    <img className='md:w-10/12 w-11/12' src={isMobile ? '/IMAGES/titlemob1.png' :'/IMAGES/title5.png'} alt="" />
                </div>
                <div className='px-7 md:px-0'>
                    <RtfitButton size='medium' text='¡LO QUIERO!' onClick={() => navigate(RTFIT_URLS.REGISTER)} />
                </div>
            </Landing>
            <div className='w-full h-60 bg-rtfitBlack flex flex-col items-center justify-center'>
                <img src="/IMAGES/title6.png" alt="" />
                <div className='w-full flex items-start mt-6 justify-center'>
                    <div className='w-1/5 flex flex-col justify-start items-center'>
                        <img src="/IMAGES/icono1.png" alt="" />
                        <div className='mt-4 text-l text-white text-center font-bold'>
                            FUERZA Y<br /> RESISTENCIA ADAPTABLES A TU NIVEL
                        </div>
                    </div>
                    <div className='w-1/5 flex flex-col justify-start items-center'>
                        <img src="/IMAGES/icono2.png" alt="" />
                        <div className='mt-4 text-l text-white text-center font-bold'>
                            ACTITUD Y <br />TRABAJO CONSCIENTE
                        </div>
                    </div>
                    <div className='w-1/5 flex flex-col justify-start items-center'>
                        <img src="/IMAGES/icono3.png" alt="" />
                        <div className='w-1/2 mt-4 text-l text-white text-center font-bold'>
                            GANAS Y <br />MOTIVACIÓN
                        </div>
                    </div>
                    <div className='w-1/5 flex flex-col justify-start items-center'>
                        <img src="/IMAGES/icono4.png" alt="" />
                        <div className='mt-4 text-l text-white text-center font-bold'>
                            CAMBIO DE HÁBITOS Y<br /> CONSTANCIA
                        </div>
                    </div>
                </div>
            </div>
            <Landing background='/IMAGES/fondo5.png'>
                <div className='w-full'>
                    <img className='w-10/12 m-0' src="/IMAGES/title7.png" alt="" />
                </div>
                <div className='text-l text-black m-0'>
                    ¿Te gustaría saber más? Dejanos tu número, y nos contactaremos con vos:
                </div>
                <div className='mt-8 flex w-9/12'>
                    <input type='tel' className='mr-4 bg-rtfitGray text-center items-center w-full' placeholder='Celular' />
                    <RtfitButton text='ENVIAR' onClick={() => console.log('ENVIAR')} />
                </div>
            </Landing>
            <div className='mt-14 w-full h-90 bg-white flex flex-col items-center justify-center'>
                <img src="/IMAGES/title8.png" alt="" />
                <div className='mt-6 items-center justify-center'>
                    <RtfitButton darkcolor color='rtfitBlack' size='medium' text='VER PLANES' onClick={() => navigate(RTFIT_URLS.REGISTER)} />
                </div>
            </div>
            <Packs />
            <div className='mt-32 relative'>
                <img src="/IMAGES/fondo6.png" alt="" />
                <div className='absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full'>
                    <img src="/IMAGES/title9.png" alt="" />
                    <div className='w-4/12 text-center text-rtfitBlack'>
                        Un modelo no convencional que rompe los paradigmas del ejercicio físico tradicional. Se trata de un entrenamiento donde se contempla, en cada ejercicio, a todos los grupos musculares. Sin repeticiones ni series, <span className='font-bold'>¡Sin aburrirte ni caer en rutinas tediosas y difíciles de mantener en el tiempo!</span>
                    </div>
                </div>
            </div>
            <div className='w-full h-80 flex flex-col items-center justify-center bg-rtfitBlack'>
                <img src="/IMAGES/title10.png" alt="" />
            </div>
            <div className='flex flex-col items-center justify-center w-full'>
                <div className='text-2xl text-center text-rtfitBlack font-bold my-20'>
                    Escribinos por cualquier consulta, <br /><span className='font-normal'>¡estamos acá para vos!</span>
                </div>
                <div className='flex items-center justify-center mb-16 w-8/12'>
                    <div className='flex flex-col mr-10 w-full items-center justify-start'>
                        <input type='text' className='text-light border-b-2 w-full mb-8 border-rtfitGray' placeholder='Nombre' />
                        <input type='email' className='text-light border-b-2  mb-8 w-full border-rtfitGray' placeholder='Email' />
                        <input type='tel' className='text-light border-b-2 mb-8 w-full border-rtfitGray' placeholder='Teléfono' />
                        <input type='text' className='text-light border-b-2 w-full border-rtfitGray' placeholder='Ocupación' />
                    </div>
                    <div className='flex flex-col w-full items-start justify-start'>
                        <textarea rows='5' className='text-light border-b-2 w-full mb-8 border-rtfitGray resize-none' placeholder='Consulta' />
                        <RtfitButton text='ENVIAR' onClick={() => console.log('ENVIAR')} />
                    </div>
                </div>
                <div className='w-full h-40 flex items-center justify-center bg-rtfitGray'>
                    <img className='mr-36' src="/IMAGES/fb.png" alt="" onClick={() => console.log('fb')} />
                    <img src="/IMAGES/ig.png" alt="" onClick={() => console.log('ig')} />
                    <img className='ml-36' src="/IMAGES/mail.png" alt="" onClick={() => console.log('mail')} />
                </div>
            </div>
            <div className='w-full h-10 flex items-center justify-center text-xs font-bold text-white bg-rtfitBlack'>
                Copyright 2021 © Diseño por graphicmo
            </div>
        </div>


    );
}
