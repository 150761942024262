import {
	USER_ADD, USER_EDIT, USER_DEL,
    FREE_ADD, FREE_DEL, FREE_EDIT,
    ADMIN_ADD, ADMIN_DEL, ADMIN_EDIT,
    CLASS_ADD, CLASS_DEL, CLASS_EDIT,
    PACK_ADD, PACK_DEL, PACK_EDIT, 
	LOAD_CONFIG, LOAD_LIST
} from './actions';

const initialState = {
	users: [],
	free:[],
	packs:[],
	classes: [],
	admins: [],
	freeAccounts: [],
	config:{}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
	//CONFIG
		case LOAD_CONFIG: {
			return {
				...state,
				config: action.config
			}
		}
	// LISTAS
		case LOAD_LIST: {
			return {
				...state,
				[action.listName]: action.list
			  };
		}
	// USUARIOS
	  case USER_ADD: {
		const listName = action.user._id.split(':')[0];
		console.log(listName);
		console.log(action.user);
		return {
		  ...state,
		  [listName]: state[listName].concat(action.user)
		};
	  }
	  case USER_DEL: {
		return {
		  ...state,
		  users: state.users.filter(item => item.id !== action.userId)
		};
	  }
	  case USER_EDIT: {
		const newList = state.users;
		const index = newList.findIndex(item => item.id === action.user.id);
		newList[index] = action.user;
		console.log(newList[index]);
		return {
		  ...state,
		  users: newList
		};
	  }
	  // USUARIOS FREE
	  case FREE_ADD: {
		return {
		  ...state,
		  frees: state.frees.concat(action.user)
		};
	  }
	  case FREE_DEL: {
		return {
		  ...state,
		  frees: state.frees.filter(item => item.id !== action.userId)
		};
	  }
	  case FREE_EDIT: {
		const newList = state.frees;
		const index = state.frees.findIndex(item => item.id === action.user.id);
		newList[index] = action.user;
		return {
		  ...state,
		  frees: newList
		};
	  }
	  // USUARIOS ADMIN
	  case ADMIN_ADD: {
		return {
		  ...state,
		  admins: state.admins.concat(action.user)
		};
	  }
	  case ADMIN_DEL: {
		return {
		  ...state,
		  admins: state.admins.filter(item => item.id !== action.userId)
		};
	  }
	  case ADMIN_EDIT: {
		const newList = state.admins;
		const index = state.admins.findIndex(item => item.id === action.user.id);
		newList[index] = action.user;
		return {
		  ...state,
		  admins: newList
		};
	  }
	  // CLASES
	  case CLASS_ADD: {
		return {
		  ...state,
		  classes: state.classes.concat(action.classData)
		};
	  }
	  case CLASS_DEL: {
		return {
		  ...state,
		  classes: state.classes.filter(item => item.id !== action.classId)
		};
	  }
	  case CLASS_EDIT: {
		const newList = state.classes;
		const index = state.classes.findIndex(item => item.id === action.classData.id);
		newList[index] = action.class;
		return {
		  ...state,
		  classes: newList
		};
	  }
	  // PAKCS
	  case PACK_ADD: {
		return {
		  ...state,
		  packs: state.packs.concat(action.pack)
		};
	  }
	  case PACK_DEL: {
		return {
		  ...state,
		  packs: state.packs.filter(item => item.id !== action.packId)
		};
	  }
	  case PACK_EDIT: {
		const newList = state.packs;
		const index = state.packs.findIndex(item => item.id === action.pack.id);
		newList[index] = action.pack;
		return {
		  ...state,
		  packs: newList
		};
	  }
	  default: {
		return state;
	  }
	}
};