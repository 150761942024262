import { useState } from 'react';
import RtfitButton from '../rtfitButton.jsx';

function Card(props) {
	const { name, classList, aditionList, price } = props;

	return (
		<div className='p-4 m-2 h-full flex flex-col items-center justify-start shadow-lg'>
			<h1 className='text-3xl font-bold text-rtfitAcent text-center'>
				Plan <br /> {name}
			</h1>
			<ul className='flex flex-col text-sm items-start justify-start mt-12'>
				{classList.map((item, index) => <li className='flex items-center mb-2 justify-center' key={index}><i className='ri-checkbox-blank-circle-fill mr-1 text-xxs text-rtfitAcent'/>{item}</li>)}
				{aditionList.map((item, index) => <li className='flex items-center mb-2 justify-center' key={index}><h1 className='text-sm mr-1 font-extrabold'>+</h1>{item}</li>)}
			</ul>
			<div className='flex flex-col items-center justify-end h-full'>
                <h1 className='text-3xl text-rtfitBlack'>${price} .-</h1>
                <div className='text-center text-xs mb-4 text-rtfitBlack'><span className='font-black'>MENSUALES</span><br/>PESOS ARGENTINOS</div>
                <RtfitButton darkcolor text='SUSCRIBIRME' color='rtfitBlack' />
            </div>
		</div>
	);
}

export default function Packs(props) {
	const [list, setList] = useState([
		{
			name: 'básico',
			classList: [
				'Acceso a Nivel Inicial',
				'Acceso a Nivel Intermedio',
				'Acceso a Nivel Avanzado',
			],
			aditionList: ['Guía nutricion'],
			price: '2100',
		},
		{
			name: 'intermedio',
			classList: [
				'Acceso a Nivel Inicial',
				'Acceso a Nivel Intermedio',
				'Acceso a Nivel Avanzado',
			],
			aditionList: ['50 clases complementarias', 'Guia nutricion'],
			price: '2500'
		},
		{
			name: 'full',
			classList: ['Acceso a los 3 niveles'],
			aditionList: [
				'50 clases complementarias',
				'Guía nutricion',
				'Plan RTFIT 8 semanas',
				'Clases en vivo',
				'Descuentos en RTWEAR',
			],
			price: '3000'
		},
	]);
	return (
		<div className='w-full h-screen flex flex-col items-center justify-center'>
			<h1 className='text-xl font-bold mb-10 mt-14'>PLANES DE SUSCRIPCIÓN</h1>
            <div className='flex items-center justify-center'>
                {list.map((pack, index) => (
                    <Card key={index} {...pack} />
                ))}
            </div>
		</div>
	);
}
