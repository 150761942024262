import { useEffect, useState } from "react";
import {A} from 'hookrouter';
import {routes} from '../constants/routes';

export default function NavBar(props) {
    const [list, setList] = useState([]);

    useEffect(() => {
        const itemList = [];

        routes.forEach( item => {
            if (item.showMenu) {
                itemList.push(item);
            }
        });

        setList(itemList);
    }, []);
    return (
        <nav className='w-1/12 bg-rtfitGreen color-white flex flex-col items-center justify-start h-screen'>
            RTFIT
            {
                list.map((item, key) => {
                    return (
                        <A href={item.url} key={key} className='flex flex-col items-center justify-center h-20 w-full cursor-pointer'>
                            <i className={item.icon}/>
                            <div>{item.title}</div>
                        </A>
                    )
                })
            }
        </nav>
    );
}