export default function Landing(props) {
    const { background } = props;

    return (
        <section className='w-full h-screen flex flex-row-reverse items-center' style={{background:`url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <div className='w-full md:w-1/2 h-full flex flex-col items-start justify-center'>
                {props.children}
            </div>
        </section>
    );
}