import { useEffect } from "react";
import { useGlobalContext } from "../../contexts/GlobalContext.jsx";
import NavBar from "../../components/NavBar.jsx";

export default function View({children}) {
        const {token} = useGlobalContext();

        useEffect(() => {
            if (!token) {
                console.log('desconectar sesion');
            }
        },[token]);
    return (
        <main className='w-screen h-screen overflow-hidden flex items-center justify-start'>
            <NavBar/>
            <div  className='w-11/12 h-screen'>
            {children}
            </div>
        </main>
    )
}