import { RTFIT_URLS } from './urls';

import Login from '../routes/login';
import Dashboard from '../routes/dashboard';
import Users from '../routes/users';
import FrontPage from '../routes/webRtfit/frontpage';
import RTFITRegister from '../routes/webRtfit/register';

// en el caso de utilizar parametros, url:'/:param1', component:({param1})=> <Component/>
// showMenu decide si se ve en el menu.
export const routes = [
    // PAGE
    { title:'FrontPage', url:RTFIT_URLS.INDEX, icon: 'ri-home-heart-fill', component: () => <FrontPage/>,  showMenu: false },
    { title:'Register Rtfit', url:RTFIT_URLS.REGISTER, icon: 'ri-home-heart-fill', component: () => <RTFITRegister/>,  showMenu: false },

    // ADMIN
    { title:'Login', url:'/admin', icon: 'ri-home-heart-fill', component: () => <Login/>, showMenu: false},
    { title:'Dashboard', url:'/admin/dashboard', icon: 'ri-dashboard-fill', component: () => <Dashboard/>, showMenu: true},
    { title:'Admins', url:'/admin/admins', icon: 'ri-shield-user-fill', component: () => <Users key="Admin" model="Admin"/>, showMenu: true},
    { title:'MP', url:'/admin/mercado-pago', icon: 'ri-service-fill', component: () => <Users key="MercadoPago" model="MercadoPago" />, showMenu: true},
    { title:'Paypal', url:'/admin/paypal', icon: 'ri-paypal-fill', component: () => <Users key="Paypal" model="Paypal" />, showMenu: true},
    { title:'Free', url:'/admin/gratis', icon: 'ri-user-5-fill', component: () => <Users key="Free" model="Free" />, showMenu: true},
    { title:'Posnet', url:'/admin/posnet', icon: 'ri-bank-card-2-fill', component: () => <Users key="Posnet" model="Posnet" />, showMenu: true},
    { title:'Packs', url:'/admin/packs', icon: 'ri-money-dollar-box-fill', component: () => <Users key="Pack" model="Pack" />, showMenu: true},
    { title:'Clases', url:'/admin/clases', icon: 'ri-video-fill', component: () => <Users key="Clase" model="Clase" />, showMenu: true},
    { title:'Config', url:'/admin/config', icon: 'ri-settings-3-fill', component: () => <div>config</div>, showMenu: true}
];