import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useGlobalContext } from '../contexts/GlobalContext';

export default function Portal() {
    const { closeModalAction, OpenModal, isMobile } = useGlobalContext();
    const container = document.getElementById('portal');

    function Modal() {
        const [style, setStyle] = useState({
            width: '40vw',
            maxHeight: '80vh',
            height: 'auto'
        });

        useEffect(() => {
            if (isMobile) {
                setStyle({
                    width: '100vw',
                    height: '100vh',
                    overflow: 'hidden'
                });
            } else {
                setStyle({
                    width: '40vw',
                    maxHeight: '80vh',
                    height: 'auto'
                });
            }
        }, []);

        return (
            <section className='absolute top-0 left-0 w-screen h-screen flex flex-col items-center justify-center bg-black bg-opacity-30'>
                <div className={`relative bg-white p-6 ${!isMobile ? 'rounded-lg shadow' : ''} flex flex-col items-center justify-start`} style={style}>
                    <div className='absolute top-2 right-2 text-right cursor-pointer font-bold text-xs rounded-full hover:bg-rtfitGreen w-4 h-4 flex items-center justify-center' onClick={closeModalAction}><i className="ri-close-line"/></div>
                    {OpenModal}
                </div>
            </section>
        )
    }
    return ReactDOM.createPortal(<Modal/>, container);
}