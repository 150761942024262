import 'remixicon/fonts/remixicon.css';
import './App.css';
import { GlobalContextProvider } from './contexts/GlobalContext.jsx';
import AppPage from './AppPage';

function App() {
  return (
	<div className="App">
	  <div className="flex flex-col items-center justify-center w-full overflow-y-auto">
			<GlobalContextProvider>
						<AppPage/>
			</GlobalContextProvider>
	  </div>
	</div>
  );
}

export default App;
