export const RTFIT_URLS = {
    INDEX: '/',
    REGISTER: '/rtfit/register'
}

export const ADMIN_URLS = {
    INDEX:'/admin',
    DASHBOARD:'/admin/dashboard',
    ADMINS:'/admin/admins',
    MERCADOPAGO:'/admin/mercado-pago',
    PAYPAL:'/admin/paypal',
    GRATIS:'/admin/gratis',
    POSNET:'/admin/posnet',
    PACKS:'/admin/packs',
    CLASES:'/admin/clases',
    CONFIG:'/admin/config'
}