import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers/reducer';

export const middle = createSagaMiddleware();


export default createStore(
    reducer,
    compose(applyMiddleware(middle), applyMiddleware(logger))
);