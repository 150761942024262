import { useState } from "react";
import RtfitButton from "../rtfitButton";
import { useGlobalContext } from "../../../contexts/GlobalContext";

export default function NativationBar(props) {
    const [active, setActive] = useState();
    const {isMobile} = useGlobalContext();
    
    return (
        <>
        <nav className='bg-rtfitGray text-white w-full h-18 py-3 flex items-center justify-between px-4 md:px-8'>
            {isMobile ? null : <img className='ml-10' alt='RTFIT' src='/IMAGES/logortfitn.png'/>}
            <div className='flex items-center justify-between w-full md:w-auto md:justify-center'>
                <RtfitButton text={isMobile ? 'CHALLENGE' : 'INGRESO CHALLENGE'} icon='ri-arrow-right-circle-line' color='rtfitChallengeLau' />
                <div className='w-full md:w-4 h-full'></div>
                <RtfitButton text={isMobile ? 'RUTINAS' : 'INGRESO ALUMNOS'} icon='ri-arrow-right-circle-line'/>
                <div className='w-0 md:w-8 h-full'></div>
            </div>
        </nav>
        </>
    );
}
