import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalContext } from "../../contexts/GlobalContext";

export default function UserForm(props) {
    const { model, itemList } = props;
    const dispatch = useDispatch();
    const { closeModalAction } = useGlobalContext();
    const [mounted, setMounted] = useState(false);
    const [values, setValues] = useState({});
    const [Categories, setCategories] = useState({});
    const [inputType, setInputType] = useState('normal');
    const categoriesReducer = useSelector(store => store.config);

    function handleSubmit() {
        if (itemList.length === 0){
            const user = values;
            user._id = `${model.Name}:${values['Email']}`;

            dispatch({type:'USER_ADD_REQUESTED', user});
        } else {
            const user = values;

            dispatch({type:'USER_EDIT_REQUESTED', user});
        }
        closeModalAction();
    }
    function handleClose() {
        closeModalAction();
    }
    function handleChange(val, value) {
        setValues( {...values, [val]: value} );
    }

    function renderInputs() {
            return model.Content.map((c, key) => {
                if (!c.notShowAtEdit && mounted) {
                    let type;
                    switch(c.Type){
                        default:
                        case 'String':
                            type = 'text';
                            break;
                        case 'Password':
                            type = 'text';
                            break;
                        case 'Tel':
                            type = 'tel';
                            break;
                        case 'Email':
                            type = 'email';
                            break;
                    }

                    let optionList = [];
                    const categorySelected = Categories.find(cat => cat.Name === c.CategoryType);
                    // optionList = categorySelected;

                    optionList = categorySelected ? categorySelected.Content : [];

                    return (
                        <div key={key} className='w-full flex items-center justify-between'>
                            <label className='font-bold text-right w-1/4 px-2'>{c.Description}</label>
                            {
                                c.Type !== 'Category' ?
                                    <input value={values[c.Name]} onChange={ev => handleChange(c.Name, ev.target.value)} type={type} className='border border-rtfitGreen p-2 mb-2 w-3/4'/>
                                    :
                                    <select className='border border-rtfitGreen p-2 mb-2 w-3/4'>
                                        {
                                            optionList.map((opt, key) => {
                                                <option key={key} value={opt.id}>{opt.description}</option>
                                            })
                                        }
                                    </select>
                            }  
                        </div>
                    );
                }
            });
    }

    useEffect(() => {
        if (!!itemList[0]) {
            setValues(itemList[0].doc);
            setMounted(true);
        } else {
            model.Content.forEach((c) => {
                handleChange(c.Name, '');
            });
            setMounted(true);
        }
    }, []);

    useState(() => {
        if (categoriesReducer) {
            setCategories(categoriesReducer.Categories);
        }
    }, []);
    return (
        <div className='flex flex-col items-center justify-start  w-3/4'>
            
            {renderInputs()}

            <div className='flex items-center justify-between'>
                <button onClick={handleSubmit} className='p-2 m-2 bg-rtfitGreen text-white'>Guardar</button>
                <button onClick={handleClose} className='p-2 m-2 bg-red-400 text-white'>Cancelar</button>
            </div>
        </div>
    );
}

/*
<input placeholder='Email' type="text" className='border border-rtfitGreen p-2 mb-2 w-full'/>
<input placeholder='Nombre y Apellido' type="text" className='border border-rtfitGreen p-2 mb-2 w-full'/>
<input placeholder='Contraseña' type="text" className='border border-rtfitGreen p-2 mb-2 w-full'/>
<input placeholder='Pais' type="text" className='border border-rtfitGreen p-2 mb-2 w-full'/>
<input placeholder='Provincia' type="text" className='border border-rtfitGreen p-2 mb-2 w-full'/>
<input placeholder='Telefono' type="text" className='border border-rtfitGreen p-2 mb-2 w-full'/>
<input placeholder='Documento' type="text" className='border border-rtfitGreen p-2 mb-2 w-full'/>
*/