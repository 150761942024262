const USER_ADD = 'USER_ADD';
const USER_DEL = 'USER_DEL';
const USER_EDIT = 'USER_EDIT';

const FREE_ADD = 'FREE_ADD';
const FREE_DEL = 'FREE_DEL';
const FREE_EDIT = 'FREE_EDIT';

const ADMIN_ADD = 'ADMIN_ADD';
const ADMIN_DEL = 'ADMIN_DEL';
const ADMIN_EDIT = 'ADMIN_EDIT';

const CLASS_ADD = 'CLASS_ADD';
const CLASS_DEL = 'CLASS_DEL';
const CLASS_EDIT = 'CLASS_EDIT';

const PACK_ADD = 'PACK_ADD';
const PACK_DEL = 'PACK_DEL';
const PACK_EDIT = 'PACK_EDIT';

const LOAD_CONFIG = 'LOAD_CONFIG';
const LOAD_LIST = 'LOAD_LIST';

export {
    USER_ADD, USER_DEL, USER_EDIT,
    FREE_ADD, FREE_DEL, FREE_EDIT,
    ADMIN_ADD, ADMIN_DEL, ADMIN_EDIT,
    CLASS_ADD, CLASS_DEL, CLASS_EDIT,
    PACK_ADD, PACK_DEL, PACK_EDIT,
    LOAD_CONFIG, LOAD_LIST
}
// 1) 5 - 6
// hacer los forms (modals) para cada tipo (1 para editar y otro para editar campo el cual seguro es el mismo para todos)

// 2) 6 - 7
// Agregar la libreria de excel cuando ya esten las listas ( list )

// 3) 7 - 8
// Editar los estilos un poco para que se vean bien